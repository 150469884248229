import React from "react";
import Helmet from 'react-helmet'
import Layout from "../../components/Layout";
import Fade from 'react-reveal/Fade';

const IndexPage = () => (
  <Layout>
    <Helmet title={`API International | Michelle Pakla`} />
    <div className="content content__project">
      <div className="content__container">
        <h1>API International</h1>
        <div className="project__container">
            <div className="project__intro">
                <h2>Introduction</h2>
                <p>API International is a manufacturer and wholesale distributor of various pipe fittings and parts.</p>
            </div>
            <Fade bottom>
              <div className="project__section">
              <img className="image"
                  sizes="(min-width: 1024px) 1024px, 100vw"
                  srcset="../../img/api-international/apiint-project-image-3-500.png 500w,
                          ../../img/api-international/apiint-project-image-3-800.png 800w,
                          ../../img/api-international/apiint-project-image-3-1200.png 1200w"                  
                  src="../../img/api-international/apiint-project-image-3.png"
                  alt="Desktop and mobile comp images" />
              </div>
              <div className="project__section">
                  <h2>Process</h2>
                  <div className="project__table">
                      <span>Role:</span><span>Lead Designer and Front-End Developer</span>
                      <span>Tools:</span><span>Figma</span>
                      <span>Tech:</span><span>CIMcloud CLI</span>
                  </div>
                  <p>API International partnered with CIMcloud to have their ERP integrated with their site, 
                    but also replace their B2C/B2B site. My focus for their design was to make sure all 
                    pertinent information was easily accessible for their customers especially on mobile. 
                    It was incredibly important to make sure that their tabular data was not only responsive, 
                    but easy to read. </p>
              </div>
              <div className="project__section">
              <img className="image"
                  sizes="(min-width: 1024px) 1024px, 100vw"
                  srcset="../../img/api-international/apiint-project-image-4-500.png 500w,
                          ../../img/api-international/apiint-project-image-4-800.png 800w,
                          ../../img/api-international/apiint-project-image-4-1200.png 1200w"                  
                  src="../../img/api-international/apiint-project-image-4.png"
                  alt="Product detail page" />
              </div>
            </Fade>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage